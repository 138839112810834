export type CitizenAccessType = "owner" | "citizen" | "guest";

export interface CitizenAccessResponse {
    id: string;
    type: CitizenAccessType;
    address: string;
    entrance: string;
    apartment: string;
    tariff_id: string;
    tariff_name: string;
    access_from: string;
    access_to: string | null;
}

/**
 * Определяет доступ жильца к квартире.
 */
export interface CitizenAccess {
    id: string;
    type: CitizenAccessType;
    address: string;
    entrance: string;
    apartment: string;
    tariffId: string;
    tariffName: string;
    accessFrom: Date;
    accessTo: Date | null;
}

export function mapToCitizenAccess(model: CitizenAccessResponse): CitizenAccess {
    return {
        id: model.id,
        type: model.type,
        address: model.address,
        entrance: model.entrance,
        apartment: model.apartment,
        tariffId: model.tariff_id,
        tariffName: model.tariff_name,
        accessFrom: new Date(model.access_from),
        accessTo: model.access_to ? new Date(model.access_to) : null,
    };
}

export type FindAllAccessesParams = {
    citizenId: string;
    limit: number;
    page: number;
};

export interface UpdateCitizenAccessParams {
    type?: CitizenAccessType;
    access_to?: Date | null;
    newOwnerId?: string | null;
}