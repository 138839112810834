import {BanCitizenButton} from "citizens/components/block_citizen_button";
import {useCitizenUserStore} from "citizens/routes/citizen_user/CitizenUserStore";
import {Citizen, CitizenAccess, getCitizenTypeName} from "citizens/types";
import {CitizenBalance} from "citizens/types/CitizenBalance";
import {Avatar} from "common/components/avatar";
import {AvatarSkeleton} from "common/components/avatar/AvatarSkeleton";
import {Button, ButtonSkeleton} from "common/components/button";
import {ContextMenu, ContextMenuItem} from "common/components/context_menu";
import {EditableValue} from "common/components/editable_value";
import IconArrowDown16x16 from "common/components/icons/IconArrowDown16x16";
import IconTrashcan16x16 from "common/components/icons/IconTrashcan16x16";
import {P} from "common/components/typography";
import {DateFormatter, formatAddress, formatToPhone} from "common/utils";
import React, {EventHandler, MouseEvent} from "react";
import Skeleton from "react-loading-skeleton";
import {Column} from "react-table";
import s from "./CitizenUserRoute.module.css";

export const infoColumns: Column<Citizen>[] = [
    {
        Header: "Фамилия",
        width: 150,
        accessor: item => item,
        Cell: (props: { value: Citizen }) => {
            const store = useCitizenUserStore();
            return (
                <div className={s.columnSecondName}>
                    <Avatar size={40} src={props.value.avatarUrl}/>
                    <EditableValue
                        className={s.columnSecondNameField}
                        displayedValue={props.value.secondName}
                        value={props.value.secondName}
                        variant="Paragraph/Small/p"
                        onValueChange={store.updateSecondName}/>
                </div>
            );
        }
    },
    {
        Header: "Имя",
        width: 125,
        accessor: "firstName",
        Cell: (props) => {
            const store = useCitizenUserStore();
            return (
                <EditableValue
                    displayedValue={props.value}
                    value={props.value}
                    variant="Paragraph/Small/p"
                    onValueChange={store.updateFirstName}/>
            );
        }
    },
    {
        Header: "Отчество",
        width: 125,
        accessor: "middleName",
        Cell: (props) => {
            const store = useCitizenUserStore();
            return (
                <EditableValue
                    displayedValue={props.value.trim() || "Не задано"}
                    value={props.value}
                    variant="Paragraph/Small/p"
                    canEmpty
                    onValueChange={store.updateMiddleName}/>
            );
        }
    },
    {
        Header: "Взаимодействие",
        width: 140,
        accessor: item => item,
        Cell: (props: { value: Citizen }) => {
            const store = useCitizenUserStore();
            return <BanCitizenButton citizen={props.value} store={store.banModal}/>
        },
    },
    {
        id: "delete-user",
        Header: "",
        width: 140,
        accessor: item => item,
        Cell: () => {
            const store = useCitizenUserStore();
            return (
                <Button
                    color="TransparentWarning"
                    heading={<IconTrashcan16x16 fill="currentColor"/>}
                    onClick={() => store.openDeleteModal()}>
                    Удалить
                </Button>
            );
        },
    },
    {
        Header: "Баланс, руб.",
        width: 170,
        accessor: "balance",
    },
    {
        Header: "Дата создания",
        width: 170,
        accessor: "createdAt",
        Cell: (props) => {
            return (
                <P variant="Paragraph/Small/p">{props.value ? DateFormatter.formatDateDefault(props.value) : ""}</P>
            );
        }
    },
    {
        Header: "Телефон",
        width: 170,
        accessor: "phone",
        Cell: (props) => {
            const store = useCitizenUserStore();
            return (
                <EditableValue
                    displayedValue={formatToPhone(props.value)}
                    mask="+# (###) ###-##-##"
                    value={props.value}
                    variant="Paragraph/Small/p"
                    onValueChange={value => store.updatePhone(`+${value}`)}/>
            );
        }
    },
    {
        Header: "Электронная почта",
        width: 200,
        accessor: "email",
        Cell: (props) => {
            const store = useCitizenUserStore();
            return (
                <EditableValue
                    displayedValue={props.value.trim() || "Не задано"}
                    value={props.value}
                    variant="Paragraph/Small/p"
                    canEmpty
                    onValueChange={value => store.updateEmail(value)}/>
            );
        }
    },
];

export const infoColumnsSkeleton = [
    {
        width: 150,
        Cell: (
            <div className={s.columnSecondName}>
                <AvatarSkeleton size={40}/>
                <Skeleton containerClassName={s.columnSecondNameSkeleton} height={16} width="70%"/>
            </div>
        )
    },
    {width: 125},
    {
        width: 140,
        Cell: <ButtonSkeleton width={90} height={26}/>
    },
    {
        width: 140,
        Cell: <ButtonSkeleton width={90} height={26}/>
    },
    {width: 170},
    {width: 170},
    {width: 170},
    {width: 200},
];

const colors: Record<CitizenBalance["color"], string> = {
    "default": "--dark-white",
    "danger": "--dark-status-danger-d300",
    "primary": "--dark-primary-main",
};

export const balanceColumns: Column<CitizenBalance>[] = [
    {
        Header: "Операция",
        id: "operation",
        width: 250,
        accessor: item => item,
        Cell: (props: { value: CitizenBalance }) => {
            return (
                <P variant="Paragraph/Small/p" style={{color: `var(${colors[props.value.color]})`}}>
                    {props.value.text}
                </P>
            );
        }
    },
    {
        Header: "Дата",
        width: 150,
        accessor: "date",
        Cell: props => {
            return <P variant="Paragraph/Small/p">{DateFormatter.formatDefault(props.value)}</P>
        },
    },
];

export const balanceSkeleton = [
    {width: 250},
    {width: 150},
];

export const accessesColumns: Column<CitizenAccess>[] = [
    {
        Header: "Статус",
        width: 110,
        accessor: item => item,
        Cell: (props: { value: CitizenAccess }) => {
            const type = props.value.type;
            const store = useCitizenUserStore();
            return (
                <ContextMenu
                    trigger={
                        <P className={s.columnType} variant="Paragraph/Small/p">
                            {getCitizenTypeName(type)}
                            <IconArrowDown16x16 fill="currentColor"/>
                        </P>
                    }
                    height={200}>

                    {type !== "owner" && (
                        <ContextMenuItem
                            onClick={() => store.updateType(props.value, "owner")}>
                            {getCitizenTypeName("owner")}
                        </ContextMenuItem>
                    )}

                    {type !== "citizen" && (
                        <ContextMenuItem
                            onClick={() => store.updateType(props.value, "citizen")}>
                            {getCitizenTypeName("citizen")}
                        </ContextMenuItem>
                    )}

                    {type !== "guest" && (
                        <ContextMenuItem
                            onClick={() => store.updateType(props.value, "guest")}>
                            {getCitizenTypeName("guest")}
                        </ContextMenuItem>
                    )}
                </ContextMenu>
            )
        },
    },
    {
        Header: "Взаимодействия",
        width: 140,
        accessor: item => item,
        Cell: (props: { value: CitizenAccess }) => {
            const store = useCitizenUserStore();
            const openDeleteModal: EventHandler<MouseEvent> = e => {
                e.stopPropagation();
                store.openDeleteAccessModal(props.value);
            };

            return (
                <Button
                    color="TransparentWarning"
                    heading={<IconTrashcan16x16 fill="currentColor"/>}
                    onClick={openDeleteModal}>
                    Удалить
                </Button>
            );
        },
    },
    {
        Header: "Адрес",
        width: 210,
        accessor: item => item,
        Cell: (props: { value: CitizenAccess }) => {
            return (
                <div>
                    <P variant="Paragraph/Small/p">{formatAddress(props.value.address)}</P>
                    <P variant="Paragraph/Small/p">
                        Пар.: {props.value.entrance} Кв.: {props.value.apartment}
                    </P>
                </div>
            )
        },
    },
    {
        Header: "Тариф",
        width: 90,
        accessor: "tariffName",
    },
    {
        Header: "Активен с",
        width: 100,
        accessor: "accessFrom",
        Cell: props => (
            <P variant="Paragraph/Small/p">
                {DateFormatter.formatDateDefault(props.value)}
            </P>
        ),
    },
    {
        Header: "Доступен до",
        width: 100,
        accessor: "accessTo",
        Cell: props => {
            const accessTo = props.value;
            if (accessTo == null) {
                return <P variant="Paragraph/Small/p">Бессрочно</P>;
            } else {
                return <P variant="Paragraph/Small/p">{DateFormatter.formatDateDefault(accessTo)}</P>;
            }
        },
    },
];

export const addressSkeleton = [
    {width: 110},
    {
        width: 140,
        Cell: <ButtonSkeleton width={80} height={26}/>,
    },
    {
        width: 210,
        Cell: (
            <div>
                <Skeleton height={16} count={3}/>
            </div>
        ),
    },
    {width: 90},
    {width: 100},
];