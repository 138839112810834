import {ForwardedRef, forwardRef, useCallback, useEffect, useState} from "react";
import useRemembered from "../../../../common/hooks/useRemembered";
import {BaseInputBox, CommonInputBoxProps} from "./BaseInputBox";
import {InputBoxItem, InputBoxRef, LoadOptionsFunc} from "./types";

export type AsyncInputBoxProps<T> = {
    selectedName?: string | null;
    loadOptions: LoadOptionsFunc<T>;
} & CommonInputBoxProps<T>;

function useAsyncLoad<T>(loadOptions: LoadOptionsFunc<T>, search: string, isFocus: boolean) {
    const _loadOptions = useRemembered(loadOptions);
    const [items, setItems] = useState<InputBoxItem<T>[]>([]);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingMore, setLoadingMore] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [isPageAlreadySet, setPageAlreadySet] = useState(false);

    const loadMore = useCallback(() => {
        if (canLoadMore && !isLoadingMore && !error && !isPageAlreadySet) {
            setPage(page => page + 1);
            setPageAlreadySet(true);
        }
    }, [canLoadMore, isLoadingMore, error, isPageAlreadySet]);

    useEffect(() => {
        if (!isFocus) {
            setPage(0);
            setPageAlreadySet(false);
            setItems([]);
            setCanLoadMore(false);
            setError(null);
        }
    }, [isFocus]);

    useEffect(() => {
        if (!isFocus) {
            return;
        }

        const controller = new AbortController();
        if (page === 0) {
            setLoading(true);
        } else {
            setLoadingMore(true);
        }

        const timeout = setTimeout(() => {
            if (controller.signal.aborted) {
                return;
            }

            _loadOptions.current(search, page)
                .then(result => {
                    if (controller.signal.aborted) {
                        return;
                    }

                    if (page === 0) {
                        if (Array.isArray(result)) {
                            setItems(result);
                            setCanLoadMore(false);
                        } else {
                            setItems(result.items);
                            setCanLoadMore(result.canLoadMore);
                        }
                    } else {
                        if (Array.isArray(result)) {
                            setItems((items) => [...items, ...result]);
                            setCanLoadMore(false);
                        } else {
                            setItems((items) => [...items, ...result.items]);
                            setCanLoadMore(result.canLoadMore);
                        }
                    }
                })
                .catch(err => {
                    if (!controller.signal.aborted) {
                        setItems([]);
                        setError(err.toString());
                    }
                })
                .finally(() => {
                    if (page === 0) {
                        setLoading(false);
                    } else {
                        setLoadingMore(false);
                    }
                    setPageAlreadySet(false);
                });
        }, 150);

        return () => {
            controller.abort();
            clearTimeout(timeout);
        }
    }, [_loadOptions, search, isFocus, page]);

    return {
        items,
        error,
        loadMore,
        isLoadingMore,
        isLoading,
    }
}

export const AsyncInputBox = forwardRef(<T extends any>(props: AsyncInputBoxProps<T>, ref: ForwardedRef<InputBoxRef>) => {
    const {
        selected,
        selectedName,
        loadOptions,
        error: userError,
        readOnly,
        onFocus: userOnFocus,
        onBlur: userOnBlur,
        ...defaultProps
    } = props;

    const [search, setSearch] = useState("");
    const [isFocus, setFocus] = useState(false);
    const {
        items,
        error,
        isLoading,
        isLoadingMore,
        loadMore,
    } = useAsyncLoad(loadOptions, search, !readOnly && isFocus);

    let value = selected;
    if (selectedName) {
        value = {
            id: -1,
            label: selectedName,
        };
    }

    return (
        <BaseInputBox
            ref={ref}
            readOnly={readOnly}
            selected={value}
            items={items}
            error={error || userError}
            search={search}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            onSearchChange={setSearch}
            onScrollToBottom={loadMore}
            onFocus={() => {
                setFocus(true);
                userOnFocus?.();
            }}
            onBlur={() => {
                setFocus(false);
                userOnBlur?.();
            }}
            {...defaultProps}/>
    );
}) as <T extends any>(props: AsyncInputBoxProps<T> & {
    ref?: ForwardedRef<InputBoxRef>
}, ref: ForwardedRef<InputBoxRef>) => JSX.Element;
